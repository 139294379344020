<template>

  <div class="div-ceeyuu-footer">
    <a href="https://www.ceeyuu.de" class="a-ceeyuu-footer" target="_blank">
      powered by
    </a>
    <img :src="CeeYuu_Logo" alt="www.ceeyuu.de" class="img-ceeyuu-footer" @click="zuCeeYuu" >
  </div>
</template>
<script>
import CeeYuu_Logo from "../../assets/CeeYuu_Logo_weiss_1200x304.png";


export default {
  name: "Footer",
  data() {
    return {
      CeeYuu_Logo: CeeYuu_Logo,
    }
  },
  components: {},
  methods: {
    zuCeeYuu() {
      window.open("https://www.ceeyuu.de");
    },
  },
  created() {

  },

}

</script>

<style lang="css" scoped>
@import "../../css/CeeYuuFooter.css";
</style>

<template>
  <div class="div-ceeyuu-footer2">
    <a href="https://www.ceeyuu.de" class="a-ceeyuu-footer2" target="_blank">
      powered by
    </a>
    <img :src="CeeYuu_Logo" alt="www.ceeyuu.de" class="img-ceeyuu-footer2" @click="zuCeeYuu" >
  </div>
</template>
<script>
import CeeYuu_Logo2 from "../../assets/CeeYuu_Logo_horizontal.png"


export default {
  name: "Footer",
  data() {
    return {
      CeeYuu_Logo: CeeYuu_Logo2,
    }
  },
  components: {},
  methods: {
    zuCeeYuu() {
      window.open("https://www.ceeyuu.de");
    },
  },
  created() {

  },

}

</script>

<style lang="css" scoped>
@import "../../css/CeeYuuFooter.css";
</style>
